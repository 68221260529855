import { ProfileConfig } from "@enlab/constants/config/profileConfig";
import { UpdateProfileVariables } from "@enlab/models/graphql/types";
import { routeConfig } from "@enlab/constants/config/routeConfig";
import { profileStructure } from "../profileStructure";

export const profileConfig: ProfileConfig = {
    structure: profileStructure,
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "entity",
        "location",
        "sex"
    ],
    formToValues: (values, originalValues): UpdateProfileVariables => {
        if (!originalValues) {
            throw Error("Can not update profile when not logged in");
        }

        let nickname: string | undefined;
        if (values.publicDisplayNameType === "nickname") {
            nickname = String(values.nickname);
        }

        return {
            profile: {
                ...originalValues,
                locale: String(values.locale) || routeConfig.defaultLocale,
                givenName: String(values.givenName),
                familyName: String(values.familyName),
                profile: {
                    ...originalValues.profile,
                    location: values.location,
                    entity: `${values.department},${values.department === "mensura_edpb_esv" ? values.location : values.role}`,
                    birthDate: values.birthDate,
                    sex: values.sex,
                    nationality: values.nationality
                },
                privacy: {
                    ...originalValues.privacy,
                    publicDisplayNameType: "fullname"
                }
            }
        };
    },
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const profile = values.profile || {};
        const privacy = values.privacy || {};

        // eslint-disable-next-line no-nested-ternary
        const department = Array.isArray(profile.entity)
            ? [profile.entity || "", undefined]
            : profile.entity ? profile.entity.split(",") : ["", null];

        return {
            givenName: values.givenName,
            familyName: values.familyName,
            birthDate: profile.birthDate,
            sex: profile.sex,
            department,
            nationality: profile.nationality,
            location: profile.location,
            email: values.email,
            publicDisplayNameType: privacy.publicDisplayNameType,
            nickname: privacy.nickname,
            locale: values.locale
        };
    }
};
