export const profileLocations = [
    {
        value: "Antwerpen",
        title: "Antwerpen"
    },
    {
        value: "Brussel",
        title: "Brussel"
    },
    {
        value: "Hasselt",
        title: "Hasselt"
    },
    {
        value: "Libramont",
        title: "Libramont"
    },
    {
        value: "Gosselies/Moeskroen",
        title: "Gosselies/Moeskroen"
    },
    {
        value: "Zwijnaarde/Brugge/Kortrijk",
        title: "Zwijnaarde/Brugge/Kortrijk"
    },
    {
        value: "Luik/Liège",
        title: "Luik/Liège"
    }
];

export const profileRoles = [
    {
        value: "Center + Algemene Directie EDPB",
        label: "Center + Algemene Directie EDPB"
    },
    {
        value: "Regionaal Managementteam & Secretariaat",
        label: "Regionaal Managementteam & Secretariaat: Regiodirecteur, Regionale Sales, Regionaal Secretariaat, Verantwoordelijke Key Accounts, Verantwoordelijke KMO, in Brussel: Verantwoordelijke Medisch/Risicobeheersing, Verantwoordelijke Administratie"
    },
    {
        value: "Bedrijfsarts",
        label: "Bedrijfsarts"
    },
    {
        value: "Verpleegkundige, MOE-chauffeur",
        label: "Verpleegkundige, MOE-chauffeur"
    },
    {
        value: "APA, Preventiedeskundige, Preventieadviseur",
        label: "APA, Preventiedeskundige, Preventieadviseur"
    },
    {
        value: "Administratie",
        label: "Administratie, Planning, Dossierbeheerder, SDC, Operational Support, Coördinatie opleidingen"
    }
];

export const profileDepartments = [
    {
        value: "mensura_edpb_esv",
        label: "Mensura EDPB +ESV",
        children: profileRoles
    },
    {
        value: "certimed",
        label: "Certimed"
    }
];
