import { INITIAL_AUTH_STATE } from "@enlab/redux/reducers/authReducer";
import { INITIAL_CONFIG_STATE } from "@enlab/redux/reducers/configReducer";
import { INITIAL_MAIN_STATE } from "@enlab/redux/reducers/mainReducer";
import { companyLogo, config } from "../config";
import { footerConfig } from "../constants/config/footerConfig";

footerConfig.companyLogo = companyLogo;

export const createInitialStore = () => {
    return {
        auth: INITIAL_AUTH_STATE,
        config: {
            ...INITIAL_CONFIG_STATE,
            ...config,
            footerConfig,
            routeConfig: {
                locales: config.routeConfig.locales,
                defaultLocale: config.routeConfig.defaultLocale,
                routes: { ...INITIAL_CONFIG_STATE.routeConfig.routes, ...config.routeConfig.routes },
                redirects: [...INITIAL_CONFIG_STATE.routeConfig.redirects, ...config.routeConfig.redirects],
                hideNavigationWhenLoggedOut: true
            }
        },
        main: INITIAL_MAIN_STATE
    };
};
