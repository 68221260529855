import { FormStructureScheme } from "@enlab/components/UI/form/formBuilderManager/types";
import { FormattedMessage } from "react-intl";
import React from "react";
import { password } from "environment";
import { getPasswordRules } from "@enlab/utils/passwordRule";
import { compareToFirstPasswordCreator } from "@enlab/utils/compareToFirstPassword";
import { nationalities } from "@enlab/constants/nationalities";
import { sex } from "./sex";
import { profileDepartments, profileLocations } from "./entities";
import { locales } from "./locales";

export const registerStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    validationRules: [
                        {
                            type: "email",
                            message: <FormattedMessage id="auth.error.invalidEmail" />
                        }
                    ],
                    required: true
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    validationRules: getPasswordRules(password)
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: <FormattedMessage id="auth.error.notEqualPassword" />
                        }
                    ],
                    required: true
                },
                {
                    title: "form.companyCodeIntro",
                    type: "p",
                    property: "companyCodeIntro",
                    hide: true
                },
                {
                    title: "form.companyCode",
                    type: "text",
                    property: "companyCode",
                    hide: true
                },
                {
                    title: "form.supportMail",
                    type: "p",
                    property: "supportMail",
                    hide: true
                }
            ]
        },
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                },
                {
                    title: "auth.fields.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true,
                    disabled: false
                },
                {
                    title: "form.nationality",
                    type: "select",
                    options: nationalities.map(nat => (
                        {
                            title: <FormattedMessage id={`nationality.${nat.code}`} />,
                            value: nat.code
                        }
                    )),
                    property: "nationality",
                    required: true
                },
                {
                    title: "form.department",
                    type: "cascader",
                    property: "department",
                    options: profileDepartments,
                    required: true
                },
                {
                    title: "address.city",
                    type: "select",
                    property: "location",
                    options: profileLocations
                },
                {
                    title: "auth.fields.selectedLanguage",
                    type: "select",
                    options: locales.map(language => (
                        {
                            title: <FormattedMessage id={`language.${language}`} />,
                            value: language
                        }
                    )),
                    property: "locale",
                    required: true
                },
                {
                    title: "form.sex",
                    type: "radio",
                    options: sex,
                    property: "sex",
                    required: true
                }
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                },
                {
                    title: "form.showActivities",
                    type: "checkbox",
                    property: "showActivities"
                }
            ]
        }
    ]
};
