import { AmbassadorRedirectCheckContainer } from "@enlab/components/ambassador/ambassadorRedirectCheck/ambassadorRedirectCheckContainer";
import { DashboardOverviewContainer } from "@enlab/pages/dashboardOverview/dashboardOverviewContainer";
import { RedirectType, ROUTES as ComponentRoutes } from "@enlab/routes/routes";
import * as React from "react";
import { ContentContainer } from "@energylab/generic-components-v2/pages/content/contentContainer";
import { GalleryContainer } from "@energylab/generic-components-v2/pages/gallery/galleryContainer";
import { WebshopContainer } from "@energylab/generic-components-v2/pages/webshop/webshopContainer";
import { ContactContainer } from "@energylab/generic-components-v2/pages/contact/contactContainer";
import * as environment from "environment";

ComponentRoutes.events.navbarOrder = 4;
ComponentRoutes.news.navbarOrder = 5;
ComponentRoutes.challenges.navbarOrder = 3;
export const ROUTES = {
    dashboard: {
        id: "dashboard",
        paths: {
            nl: "/dashboard",
            en: "/dashboard",
            fr: "/dashboard"
        },
        private: true,
        exact: true,
        component: DashboardOverviewContainer,
        navbarTextId: "menuItem.dashboard",
        navbarOrder: 1,
        hideWhenLoggedOut: true,
        hideWhenLoggedIn: true
    },
    contact: {
        id: "contact",
        exact: true,
        paths: {
            nl: "/contact",
            en: "/contact",
            fr: "/contact"
        },
        private: true,
        component: ContactContainer,
        navbarTextId: "menuItem.contact",
        navbarOrder: 9
    },
    gallery: {
        id: "gallery",
        paths: {
            nl: "/gallery",
            en: "/gallery",
            fr: "/gallery"
        },
        exact: true,
        component: GalleryContainer,
        private: true,
        navbarTextId: "menuItem.gallery",
        navbarOrder: 7
    },
    ambassadors: {
        id: "ambassadors",
        paths: {
            nl: "/ambassadeurs",
            en: "/ambassadors"
        },
        exact: true,
        component: AmbassadorRedirectCheckContainer,
        navbarTextId: "menuItem.ambassadors",
        navbarOrder: 6
    },
    webshop: {
        id: "webshop",
        paths: {
            nl: "/webshop",
            en: "/webshop",
            fr: "/webshop"
        },
        private: true,
        exact: true,
        component: WebshopContainer,
        navbarTextId: "menuItem.webshop",
        navbarOrder: 8,
        hideWhenLoggedIn: !environment.enableWebshop,
        hideWhenLoggedOut: !environment.enableWebshop
    },
    cookyPolicy: {
        id: "cookyPolicy",
        paths: {
            nl: "/cookie-policy",
            fr: "/cookie-policy",
            en: "/cookie-policy",
            de: "/cookie-policy"
        },
        exact: true,
        component: () => <ContentContainer fullPage type="cookyPolicy" />
    }
};

export const REDIRECTS: RedirectType[] = [];
