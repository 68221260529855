/* eslint-disable global-require */
import { contactConfig } from "@enlab/constants/config/contactConfig";
import { galleryConfig } from "@enlab/constants/config/galleryConfig";
import { resetPasswordConfig } from "@enlab/constants/config/resetPasswordConfig";
import { whiteListSignUpConfig } from "@enlab/constants/config/whiteListSignUpConfig";
import { nationalities } from "@enlab/constants/nationalities";
import { ConfigReducerState } from "@enlab/redux/reducers/configReducer";
import { getBrowserLocale } from "@enlab/utils/browserLocale";
import * as environment from "environment";
import { providers } from "@enlab/constants/config/providersConfig";
import { REDIRECTS, ROUTES } from "./routes";
import { metaDataConfig } from "./constants/config/metaDataConfig";
import { profileConfig } from "./constants/config/profileConfig";
import { locales } from "./constants/locales";
import { registerConfig } from "./constants/config/registerConfig";

const defaultLocale = "nl";

export const companyLogo = "https://res.cloudinary.com/energylab/image/upload/v1597660902/Mensura/MENS_CERT.png";
export const config: ConfigReducerState = {
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    profileConfig,
    metaDataConfig,
    registerConfig,
    resetPasswordConfig,
    galleryConfig,
    ambassadorSettingsConfig: {
        enableRelive: false,
        enableComments: true
    },
    socialConfig: {
        disableInstagram: false,
        disableTwitter: false,
        disableFacebook: false,
        disableLinkedIn: false,
        disableOnChallenCard: false,
        hideFacebookOnCommunity: false,
        hideTwitterOnCommunity: false,
        hideInstagramOnCommunity: false,
        hideLinkedInOnCommunity: true
    },
    ambassadorImage: "",
    auth: {
        anonymousToken: environment.anonymousToken
    },
    commentsConfig: {
        disabled: false,
        autoShowSubComments: true,
        autoShowInput: true
    },
    eventsConfig: {
        defaultView: "list",
        viewOptions: []
    },
    contentConfig: {
        showAuthor: true,
        useThemes: true,
        previewType: "card",
        enablePageBanner: true,
        pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1561558218/generic-v2/bannerimage.jpg",
        pageBannerOverlay: true,
        alignment: "left"
    },
    contactConfig: {
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1598863465/Mensura/Contact_1440x500.png",
        subjectOptions: [],
        siteKey: contactConfig.siteKey
    },
    companyLogoWidth: 210,
    companyLogo,
    companyName: "Mensura",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig: {
        enabled: false,
        coinName: "Mensura Awards"
    },
    likesConfig: {
        disabled: false
    },
    webshopConfig: {
        enabled: environment.enableWebshop,
        shopName: "Shop"
    },
    notificationsConfig: {
        enabled: false
    },
    providers,
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig: {
        locales,
        defaultLocale,
        routes: ROUTES,
        redirects: REDIRECTS,
        hideNavigationWhenLoggedOut: true
    },
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    seo: {
        pageTitle: "Vitality@Mensura",
        title: {
            nl: "Mensura",
            fr: "Mensura",
            en: "Mensura"
        },
        description: {
            nl: "",
            fr: "",
            en: ""
        },
        image: "",
        site: ""
    },
    uploadPreset: "mensura",
    newUsersConfig: {
        enabled: false,
        hasListOfNewUsers: false
    },
    leaderboardConfig: {
        pageSize: 10
    },
    socialShareConfig: {
        enabled: false,
        copyClipboardEnabled: false,
        shareWithEmail: {
            enabled: false
        }
    },
    whiteListSignUpConfig: {
        enabled: false,
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: whiteListSignUpConfig.siteKey
    }
};
