import "babel-polyfill";
import * as OfflinePluginRuntime from "offline-plugin/runtime";
import * as React from "react";
import { render } from "react-dom";
import { App } from "./app";

OfflinePluginRuntime.install({
    onUpdateReady: () => {
        OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
        window.location.reload();
    }
});

const reactContainer = document.getElementById("app-container");

render(
    <App />,
    reactContainer
);
