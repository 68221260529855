import { FormStructureScheme } from "@enlab/components/UI/form/formBuilderManager/types";
import { FormattedMessage } from "react-intl";
import React from "react";
import { nationalities } from "@enlab/constants/nationalities";
import { sex } from "./sex";
import { profileDepartments, profileLocations } from "./entities";
import { locales } from "./locales";

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            title: "auth.fields.birthDate",
            type: "date",
            property: "birthDate",
            required: true,
            disabled: false
        },
        {
            title: "form.nationality",
            type: "select",
            options: nationalities.map(nat => (
                {
                    title: <FormattedMessage id={`nationality.${nat.code}`} />,
                    value: nat.code
                }
            )),
            property: "nationality",
            required: true
        },
        {
            title: "form.department",
            type: "cascader",
            property: "department",
            options: profileDepartments,
            required: true
        },
        {
            title: "address.city",
            type: "select",
            property: "location",
            options: profileLocations,
            required: true
        },
        {
            title: "auth.fields.selectedLanguage",
            type: "select",
            options: locales.map(language => (
                {
                    title: <FormattedMessage id={`language.${language}`} />,
                    value: language
                }
            )),
            property: "locale",
            required: true
        },
        {
            title: "form.sex",
            type: "radio",
            options: sex,
            property: "sex",
            required: true
        }
    ]
};
