/* eslint-disable import/no-extraneous-dependencies */
import "@enlab/assets/icons/enlab.font.js";
import { AppWrapper } from "@enlab/components/appWrapper/appWrapper";
import { theme } from "@style/theme";
import { createInitialStore } from "@utils/createInitialStore";
import * as React from "react";
import { hot } from "react-hot-loader";
import { translations as getTranslations } from "./translations/translations";

export interface AppProps {

}

const AppComponent: React.StatelessComponent<AppProps> = () => {
    const initialStore = createInitialStore();
    const translations = getTranslations();

    return (
        <AppWrapper
            googleAnalyticsConfig={undefined}
            translations={translations}
            initialStore={initialStore}
            theme={theme}
        />
    );
};

export const App = hot(module)(AppComponent);
