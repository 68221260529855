import { RegisterConfig } from "@enlab/constants/config/registerConfig";
import { SignupMutationVariables } from "@enlab/graphql2/types";
import { routeConfig } from "@enlab/constants/config/routeConfig";
import { registerStructure } from "../registerStructure";

export const registerConfig: RegisterConfig = {
    backgroundImage: "https://res.cloudinary.com/energylab/image/upload/v1599823983/Mensura/E2020_0209_visual_vitalityv4.jpg",
    requireEmailValidation: false,
    disableColorOverlay: true,
    structure: registerStructure,
    formToValues: (values): SignupMutationVariables => ({
        email: String(values.email),
        password: String(values.password),
        profile: {
            locale: String(values.locale) || routeConfig.defaultLocale,
            givenName: String(values.givenName),
            familyName: String(values.familyName),
            profile: {
                location: values.location,
                entity: `${values.department},${values.department === "mensura_edpb_esv" ? values.location : values.role}`,
                birthDate: values.birthDate,
                sex: values.sex,
                nationality: values.nationality
            },
            privacy: {
                publicDisplayNameType: values.publicDisplayNameType as string || "fullname",
                nickname: values.nickname ? values.nickname as string : undefined,
                showActivities: Boolean(values.showActivities),
                showAsNewMember: false
            }
        }
    })
};
